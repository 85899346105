import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 400,
        backgroundColor: theme.palette.background.paper,
    },
    formRoot: {
        '& > *': {
            margin: 'auto',
            display: 'flex',
            padding: theme.spacing(1),
            justifyContent: 'center',
        },
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
}));


function ActualForm({ classes, onSubmit }) {
    return (
        <>
            <Typography variant="h5" color="textPrimary">Contact Us</Typography>
            <form
                id="contactUsForm"
                name="contactUsForm"
                className={classes.formRoot}
                onSubmit={onSubmit}
                data-netlify-honeypot="bot-field"
                data-netlify="true"
            >
                <div>
                    <TextField
                        id="name"
                        name="name"
                        label="Name"
                        aria-label="Name"
                        color="secondary"
                        variant="outlined"
                        required
                        fullWidth
                    />
                </div>
                <div>
                    <TextField
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        aria-label="Email"
                        color="secondary"
                        variant="outlined"
                        required
                    />
                    <TextField
                        id="phone"
                        name="phone"
                        label="Phone"
                        type="phone"
                        aria-label="Phone"
                        color="secondary"
                        variant="outlined"
                    />
                </div>
                <div>
                    <TextField
                        id="questions"
                        name="questions"
                        label="Questions"
                        aria-label="Questions"
                        color="secondary"
                        variant="outlined"
                        placeholder="Questions or comments"
                        multiline={true}
                        fullWidth
                        InputProps={{ style: { height: 90, width: '100%' } }}
                    />

                    <input type="hidden" name="form-name" value="contactUsForm" />
                </div>


                <Button type="submit" size="large" variant="outlined">Submit</Button>
            </form>
        </>
    )
}

function Thanks() {
    return (
        <Typography variant="h5">We'll be in touch shortly!</Typography>
    );
}

export default function ContactUsForm() {
    const classes = useStyles();

    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e)=> {
        e.preventDefault();
        let myForm = document.getElementById('contactUsForm');
        console.log('myForm', myForm);
        let formData = new FormData(myForm);
        console.log('submitting form', new URLSearchParams(formData).toString());
        fetch('/', {
            method: 'POST',
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString()
        })
        .then(()=> {
            console.log('Form successfully submitted');
            setSubmitted(true);
        })
        .catch((error)=> {
            console.error('something went wrong while submitting the form', error);
        });
    }

    return (
        <div className={classes.root}>
            {submitted
                ? <Thanks />
                : <ActualForm classes={classes} onSubmit={handleSubmit} />
            }
        </div>
    );
}
