/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

// import Header from './header'
import './layout.css'
import { Box, makeStyles, Typography } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
  },
  footer: {
    padding: 10,
    borderTop: '1px solid #CCC',
  },
  mainWrapper: {
    backgroundColor: theme.palette.background.paper,
    margin: `0 auto`,
    maxWidth: 1600,
    padding: `0px`,
  }
}));


const Layout = ({ children }) => {

  const classes = useStyles();

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div className={classes.root}>
          {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
          <div
            className={classes.mainWrapper}
          >
            <main>{children}</main>

            <footer className={classes.footer}>
              <Box display="flex" alignContent="center" alignItems="center" justifyContent="space-between">
                <Typography color="textPrimary" variant="caption">© {new Date().getFullYear()}, Lil Brownie House</Typography>
                <Typography color="textPrimary" variant="caption">Made with <span role="img" aria-label="love">❤️</span>&nbsp;&nbsp;in Mumbai</Typography>
              </Box>
            </footer>
          </div>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
