import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ImageList from '../components/imageList'

import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import { Box } from '@material-ui/core'
import ContactUsForm from '../components/contactUsForm';
import MeetYourBaker from '../components/meetYourBaker';
import TitleImage from '../components/titleImage';
import EgglessSection from '../components/egglessSection';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  bottomBox: {
    backgroundColor: theme.palette.background.paper,
    height: 50,
  },
  sectionWrapper: {
    padding: `${theme.spacing(6)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(12)}px ${theme.spacing(3)}px`,
    },
  },
}));

const IndexPage = () => {

  const classes = useStyles()

  return(
    <Layout>
      <SEO />

      <TitleImage />

      <Divider />

      <div className={classes.sectionWrapper}>
        <ImageList />
      </div>

      <EgglessSection />

      <div className={classes.sectionWrapper}>
        <MeetYourBaker />
      </div>

      <ContactUsForm />

      <Box className={classes.bottomBox}/>
    </Layout>
  )
}

export default IndexPage
