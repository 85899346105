import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { Grid, Typography } from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import heartIcon from '../images/HeartIcon.ico';
import Carousel, { Modal, ModalGateway } from 'react-images';


const useStyles = makeStyles((theme) => ({
  gridList: {
    flex: 1,
  },
  gridListTile: {
    cursor: 'pointer',
  },
  description: {
    marginTop: theme.spacing(3),
    // fontSize: '1.4rem',
  },
  heartIconImg: {
    height: 35,
    marginBottom: -3,
    [theme.breakpoints.up('md')]: {
      height: 45,
      marginBottom: -4,
    },
  },
  item1: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  item2: {
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
}));



function ImageList(props) {
  const classes = useStyles();

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((index)=> () => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const photos = [
    {
      src: 'https://i2.wp.com/www.sugarspunrun.com/wp-content/uploads/2017/02/Best-Brownie-Recipe-1-of-1.jpg',
      title: 'Brownie 1',
      cols: 1,
      width: 1,
      height: 1,
    },
    {
      src: 'https://www.thespruceeats.com/thmb/3hvrtwbF58dFbQU6b7E3gHKBmM8=/450x0/filters:no_upscale():max_bytes(150000):strip_icc()/brownies-for-two-recipe-912466-hero-01-5c3aaed2c9e77c00015d934b.jpg',
      title: 'Brownie 2',
      cols: 1,
      width: 1,
      height: 1,
    },
    {
      src: 'https://www.sprinklesandsprouts.com/wp-content/uploads/2015/08/NEW-Birthday-Browines3.jpg',
      title: 'Brownie 3',
      cols: 1,
      width: 1,
      height: 1,
    },
    {
      src: 'https://ifoodreal.com/wp-content/uploads/2014/02/single-black-bean-brownies-recipe.jpg',
      title: 'Brownie 4',
      cols: 1,
      width: 1,
      height: 1,
    },
    {
      src: 'https://www.twopeasandtheirpod.com/wp-content/uploads/2019/06/Brownies-7-650x975.jpg',
      title: 'Brownie 5',
      cols: 1,
      width: 1,
      height: 1,
    },
    {
      src: 'https://kirbiecravings.com/wp-content/uploads/2017/08/single-serving-nutella-brownie-7-700x677.jpg',
      title: 'Brownie 6',
      cols: 1,
      width: 1,
      height: 1,
    },
    {
      src: 'https://lifemadesweeter.com/wp-content/uploads/Best-Fudgy-Brownies-Photo-Recipe-Picture-1-5.jpg',
      title: 'Brownie 7',
      cols: 1,
      width: 1,
      height: 1,
    },
    {
      src: 'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/singleimagebug-gluten-free-brownies-1564081757.jpg?crop=1.00xw:0.632xh;0,0.139xh&resize=480:*',
      title: 'Brownie 8',
      cols: 1,
    },
  ];

  const getGridCellHeight = ()=> {
    if (isWidthUp('sm', props.width)) {
      return 200;
    }

    return 100;
  }


  return (
    <Grid container spacing={3} justify="center" alignItems="center">
      <Grid item xs={12} md={8} className={classes.item1}>

        <GridList cellHeight={getGridCellHeight()} className={classes.gridList} cols={4}>
          {photos.map((tile, index) => (
            <GridListTile className={classes.gridListTile} key={tile.title} cols={tile.cols || 1} onClick={openLightbox(index)}>
              <img src={tile.src} alt={tile.title} />
              {/* <GridListTileBar title={tile.title} /> */}
            </GridListTile>
          ))}
        </GridList>
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                hideControlsWhenIdle={false}
                styles={{
                  footer(currentStyles) {
                    return { ...currentStyles, display: 'inline-block', textAlign: 'center' };
                  },
                  footerCaption(){
                    return { fontSize: 30, };
                  },
                  footerCount() {
                    return { display: 'none', };
                  }
                }}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Grid>
      <Grid item xs={12} md={4} className={classes.item2}>
        <Typography color="textPrimary" variant="h3">Love at first bite <img alt="love" aria-label="love" className={classes.heartIconImg} src={heartIcon} /></Typography>
        <Typography color="textPrimary" variant="subtitle1" className={classes.description}>Try our assortment of brownies, all guaranteed delicious,
          all made with the same love and care. Each brownie is handcrafted exquisitely to
          ensure every bite is more delicious than the last.
          They make the perfect snack whether it's
          a special occasion or just that little pick me up after a long day.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withWidth()(ImageList);
