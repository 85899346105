import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Typography } from '@material-ui/core'
import ChocolateBowlImg from '../images/chocolate-bowl-2.jpg'
import VegetarianFriendlyImg from '../images/vegetarian-friendly.png'
import BrownieBg from '../images/brownie-bg-3.jpg'


const useStyles = makeStyles(theme => ({
  titleBox: {
    background: `linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5) ), url(${BrownieBg})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    minHeight: 800,
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
  },
  paperBackdrop: {
    background: theme.palette.background.paper,
    padding: theme.spacing(4),
    textAlign: 'center',
    maxWidth: '70%',
    maxHeight: '70%',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8),
    }
  },
  veggieFriendly: {
    maxWidth: '30%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  }
}));

export default function EgglessSection() {

    const classes = useStyles()


    return (
        <Box className={classes.titleBox}>

          <Box className={classes.paperBackdrop}>
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12} md={6}>
                <Typography color="textPrimary" variant="h4">Deliciously Eggless</Typography>
                <Typography color="textPrimary" variant="subtitle1" style={{ marginTop: 50 }}>
                    We don't compromise on taste while making sure our brownies satisfy a range of dietary concerns.
                </Typography>

                <img alt="Veggie Friendly" src={VegetarianFriendlyImg} className={classes.veggieFriendly} />
              </Grid>
              <Grid item xs={12} md={6}>
                <img alt="Chocolate Bowl" src={ChocolateBowlImg} style={{maxWidth: '100%', maxHeight: '100%'}} />
              </Grid>
            </Grid>
          </Box>
        </Box>
    )
}
