import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import PersonPlaceholder from '../images/person-placeholder.jpg';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
  },
  gridList: {
    flex: 1,
  },
  description: {
    marginTop: theme.spacing(3),
    // fontSize: '1.4rem',
  },
  item1: {
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  item2: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  bakerGirl: {
    height: 400,
    background: `url(${PersonPlaceholder})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }
}));



function MeetYourBaker(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={3} justify="center" alignItems="center">
      <Grid item xs={12} md={4} className={classes.item1}>
        <Typography color="textPrimary" variant="h3">Meet your baker <span role="img" aria-label="baker">👩‍🍳</span></Typography>
        <Typography color="textPrimary" variant="subtitle1" className={classes.description}>
          Our baker-in-chief Priyanka Patil. Aliquam id arcu in tellus eleifend fermentum.
          Nulla varius lacus vitae turpis pharetra luctus.
          Ut ac quam ipsum. Suspendisse sollicitudin sagittis libero, vel dignissim urna dapibus quis.
          Aenean nunc tellus, convallis nec lobortis at, rutrum eget velit. Donec mi nibh, consectetur id malesuada et, commodo eu sapien.
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} className={classes.item2}>
        <div className={classes.bakerGirl}></div>
      </Grid>
    </Grid>
  );
}

export default withWidth()(MeetYourBaker);
