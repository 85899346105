import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Typography } from '@material-ui/core'
import Logo from '../images/lil-brownie-house-logo.png';
import BgImage from '../images/bg-brownies.jpg'


const useStyles = makeStyles(theme => ({
    titleBox: {
        background: `linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5) ), url(${BgImage})`,
        backgroundAttachment: 'fixed',
        height: 1000,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: 20
    },
    titleText: {
        color: 'white',
        textShadow: '4px 4px 8px black',
    },
}));

export default function TitleImage() {

    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
    )

    const classes = useStyles()


    return (
        <Box className={classes.titleBox}>

            <img alt="Logo" src={Logo} style={{ marginBottom: 150, width: 250, height: 250 }} />

            <Typography className={classes.titleText} variant="h1">{site.siteMetadata.title}</Typography>
            <Typography className={classes.titleText} variant="h5" style={{ marginTop: 50 }}>
                {site.siteMetadata.description}
            </Typography>
        </Box>
    )
}
